@import '@/styles/artifacts.scss';




























































































.page {
  display: flex;
  flex-direction: column;
  padding: 40px;
}
.header {
  display: flex;
  margin-bottom: 20px;
}
.title {
  color: colorVodafone(black);
  font-size: fontSize(fs-200);
  line-height: lineHeight(lh-reset);
}
.sub-title {
  color: colorVodafone(grey);
  font-size: fontSize(fs-100);
  line-height: lineHeight(lh-reset);
  margin-top: 5px;
}
.logo {
  margin-left: auto;
  width: 65px;
}
.divider {
  width: 100%;
  height: 3px;
  background-color: #ddd;
}
.info {
  margin: 20px 0 20px 0;
  display: flex;
}
.maintenance {
  width: 35%;
}
.info-items {
  display: flex;
  flex-wrap: wrap;
}
.spot {
  width: 65%;
}
.check-points {
  margin: 20px 0 20px 0;
}
.theader {
  font-size: fontSize(fs-50);
  line-height: lineHeight(lh-reset);
  color: colorVodafone(vodafone-red);
}

@import '@/styles/artifacts.scss';























.info-item {
  margin-top: 10px;
  padding: 0 5px 0 5px;
  min-width: 110px;
}
.label {
  color: colorVodafone(vodafone-red);
  font-size: fontSize(fs-50);
  line-height: lineHeight(lh-reset);
}
.value {
  color: colorVodafone(grey);
  font-size: fontSize(fs-100);
  line-height: lineHeight(lh-reset);
}
